<template>
	<div class="history-body">
		<div id="container"></div>
	</div>
</template>
<script>
	import AMapLoader from '@amap/amap-jsapi-loader';
	import {
		shallowRef
	} from '@vue/reactivity'
	export default {
		data() {
			return {
				data: [],
			}
		},
		setup() {
			const map = shallowRef(null);
			return {
				map
			}
		},
		methods: {
			initMap(data) {
				var _this = this;
				// 清空覆盖物
				if(this.map){
					this.map.clearMap();
				}
				// 处理足迹数据
				this.data = [];
				for(let i = 0;i < data.length; i++) {
					this.data.unshift([data[i].longitude,data[i].latitude]);
				}
				AMapLoader.load({
					key: "f0a74a4ce476bbf1ff603389e5ff3105", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ['AMap.Scale'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					// 设置地图容器id
					_this.map = new AMap.Map("container", {
						viewMode: "2D", //是否为3D地图模式
						zoom: 5 ,//初始化地图级别
						center: [119.947, 31.7728]
					});
					// 创建历史足迹轨迹折线
					this.prolyline = new AMap.Polyline({
						path: this.data,
						borderWeight: 2,
						strokeColor: '#00aaff',
						lineJoin: 'round',
						showDir: true
					})
					// 创建 起点Icon 实例：
					var start_icon = new AMap.Icon({
						size: new AMap.Size(50, 50), // 图标尺寸
						image: '//jk125com-1310001488.cos.ap-nanjing.myqcloud.com/store_13/2022-08-08/1659921970118.png', // Icon的图像
						imageOffset: new AMap.Pixel(-15,-41),
						imageSize: new AMap.Size(50, 50) // 根据所设置的大小拉伸或压缩图片
					});
					// 历史轨迹-起点
					var start_marker = new AMap.Marker({
						position: new AMap.LngLat(this.data[0][0],this.data[0][1]),
						offset: new AMap.Pixel(-10, -10),
						icon: start_icon, // 添加 Icon 图标 URL
						zoom: 13,
						title:  data[0].addr
					});
					// 创建 终点Icon 实例
					var end_icon = new AMap.Icon({
						size: new AMap.Size(50, 50), // 图标尺寸
						imageOffset: new AMap.Pixel(-13,-40),
						image: '//jk125com-1310001488.cos.ap-nanjing.myqcloud.com/store_13/2022-08-08/165992204951.png', // Icon的图像
						imageSize: new AMap.Size(50, 50) // 根据所设置的大小拉伸或压缩图片
					});
					// 历史轨迹-终点
					var end_marker = new AMap.Marker({
						position: new AMap.LngLat(this.data[this.data.length-1][0],this.data[this.data.length-1][1]),
						offset: new AMap.Pixel(-10, -10),
						icon: end_icon, // 添加 Icon 图标 URL
						zoom: 13,
						title: data[data.length-1].addr
					});
					// 听见已创建好的实例
					this.map.add([_this.prolyline,start_marker])
					//如果足迹只有一个则不添加终点标记
					if(this.data.length > 1) {
						this.map.add(end_marker)
					}
					this.map.setFitView()
				}).catch(e => {
					console.log(e);
				})
			}
		}
	}
</script>

<style scoped="scoped">
	#container {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 800px;
	}
	/deep/ .amap-icon {
		overflow: initial !important;
	}
</style>
